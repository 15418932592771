import React, { useContext, useEffect, useRef, useState } from 'react'
import {useNavigate,useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';
import { baseUrl, data, headers } from '../config/config';
import './style/quiz.css'
import axios from 'axios';
import MsgAlert from './MsgAlert';
import LoaderComponent from './Loader';
import './style/home.css';
import headerImage from './themeimg/assets/logo.png'
import { Context } from '../Context/Context';
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';


export default function Quiz(props) {

  const location = useLocation();
  const timerIntervalRef = useRef(null);
  console.log(location)
  const [selectedAns,setSelectedAns] = useState(null);
  const [queData,setQueData] = useState(location.state.nextQue);
  const [availableLevel,setAvailableLevel] = useState(location.state.availableLevel);
  const [radioChecked,setRadioChecked] = useState(false);
  const [checkAnsData,setCheckAnsData] = useState();
  const [alertBox,setAlertBox] = useState(false);
  const [bntLoader,setBtnLoader] = useState(false)
  const [submitBtnShow,setSubmitBtn] = useState(true)
  const [loaderMainScreen,setLoaderMainScreen] = useState(false);
  const [timer, setTimer] = useState(10);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const { toggleNewModal } = useContext(Context);
  const lang = localStorage.getItem("appLang")
  console.log("data",queData)

  // const baseUrl = props.baseUrl
  // const baseUrl = 'https://dtapis.bngrenew.com/api/'
  const resource = props.configData.resources;
  // const msisdn = sessionStorage.getItem("msisdn")

  // api parameters
  // var headers = props.headers;
  // var data = props.data;

  const navigate = useNavigate();
  const gotoHome = () => {
    navigate('/home');
  }

  function optionSelected(key) {
    setRadioChecked(true)
    setSelectedAns(key+'')
  }

  const getAvailableLevelsForUnlockApi = async () => {
    // data['msisdn']= msisdn;
    const response = await axios({
        method: 'post',
        url: baseUrl+'getAvailableLevelsForUnlock',
        data: {...data , msisdn: localStorage.getItem('msisdn')},
        headers: headers,
    })
    setAvailableLevel(response.data);
  }

  //get question from api
  const getNextQuestionApi = async () => {
    setLoaderMainScreen(true)
    const response = await axios({
        method: 'post',
        url: baseUrl+'getNextQuestion',
        data: {...data , msisdn: localStorage.getItem('msisdn')},
        headers: headers,
    })
    setQueData(response.data)
    if (response.data.subStatus === 'EndOfQuestion') {
      // getAvailableLevelsForUnlockApi();
      window.open("/quizmodal","_self")
      // toggleNewModal(true)
    }
    setLoaderMainScreen(false)
    setSubmitBtn(true)

  }
 // check ans api
 const checkAnswerApi = async () => {
  data['answer'] = selectedAns ? selectedAns : "0" ;
  data['questionId'] = queData.questionId;
  const response = await axios({
    method: 'post',
    url: baseUrl+'checkAnswer',
    data: {...data , msisdn: localStorage.getItem("msisdn")},
    headers: headers,
  })
  const status = response.data.subStatus
  let guiEvents={};
  guiEvents['page']="quiz";
  guiEvents["event"] = "question_played";
  guiEvents["status"] = status;
  SendGuiDataEvents(guiEvents);
  console.log('check ans=>',response.data)
  setAlertBox(true);
  setSubmitBtn(false)
  // setLoaderMainScreen(false)
  setCheckAnsData(response.data);
  setTimeout(() => {
    getNextQuestionApi();
    setRadioChecked(false)
    setAlertBox(false);
    // setBtnLoader(false)
    // setLoaderMainScreen(true)

  },1700);
  //getNextQuestionApi();
}

// check submitted ans
function CheckAns() {
  setLoaderMainScreen(true);
  checkAnswerApi();
}


// function optionSelected(key) {
//   setRadioChecked(true);
//   setSelectedAns(key + '');
//   console.log("selectedanswer",selectedAns)
//   setIsOptionSelected(true);
//   // CheckAns();
//   // moveToNextQuestion(); // Move to the next question when an option is selected
// }
function optionSelected(key) {
  if (!isOptionSelected) { // Allow selection only if no option is selected yet
    setRadioChecked(true);
    setSelectedAns(key + '');
    setIsOptionSelected(true);
  }
}

useEffect(() => {
  // Start the timer when the question is displayed
  if (queData && !isOptionSelected) {
    setTimer(10); // Reset timer to 10 seconds for each new question
    const timerId = setInterval(() => {
      setTimer((t) =>{
          if(t > 0){
            return t-1;
          }    
          else{
            clearInterval(timerId);
            return 0;
          }
      } )// Decrease timer every second
    }, 1000);
    timerIntervalRef.current = timerId;

    // Clean up the interval on unmount or before resetting the timer
    return () => clearInterval(timerId);
  }
}, [queData, isOptionSelected]);

// useEffect(() => {
//   if (timer === 0) {
//     setIsOptionSelected(true);
//     // setSelectedAns("0")
//     // Timer has reached 0, automatically check the answer and load next question
//     CheckAns(); // Assuming CheckAns function will handle loading the next question
//   }
// }, [timer]);

useEffect(() => {
  if (timer === 0 || selectedAns) {
    // Timer has reached 0 or an option is selected, automatically check the answer and load the next question
    CheckAns();
  }
}, [timer, selectedAns, isOptionSelected]);


useEffect(() => {
  if (queData) {
    setIsOptionSelected(false);
    setSelectedAns(null) // Reset option selection when a new question is displayed
  }
}, [queData]);
useEffect(()=>{
  let guiEvents={};
    guiEvents['page']="quiz";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);
},[])

return (
    <>
        {(queData || availableLevel) ?
          <div>
          <div>
            <div className="container ">
              <div className="background-theme">
                <div className='contest-container '>
                <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
                  {/* <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
                      </div> */}
                    {/* <h5 className='has-text-black has-text-centered '>DREAM TRAVEL</h5> */}
                    <img className="w-28 relative right-6 mx-auto" src={headerImage} />
                  </div>
                 
                  <div className='bottom-white-bg bg-[#F8F8F8] bg-cover bg-no-repeat relative pt-12'>
                    <div className={ queData.subStatus=='EndOfQuestion' ?'card card-height ':'card px-4 py-4 '}>
                   
                    <div className='has-text-centered font-semibold'>{txt.ques[lang]}</div>
                    <div className=' has-text-centered float-right'>
                    <div className='timer-container w-16 '>
        <div className='timer'>
          {timer >= 10 ? `00:${timer}` : `00:0${timer}`}
        </div>
      </div></div>
                      <div className= 'card-container has-text-centered'>
                     
                        {
                         queData.subStatus != 'EndOfQuestion' && queData && 
                         <div className='question-section'>
                          <div className='question-head font-bold pt-6 text-'>{queData.questionString}</div>
                            <div className='question-options'>
                              { loaderMainScreen && <LoaderComponent/>}
                             {
                              queData.optionString.map((option,key) =>{
                                return (
                                  <div className='que-option-container' key={key} onClick={() => optionSelected(key+1)}>
                                   <label htmlFor={key} className='ques-option-span' >  <div><input type='radio' id = {key} value={option}  name="options" checked = {selectedAns == key+1 ? radioChecked : false } />&nbsp; {option}</div></label>
                                  </div>
                                )
                              })
                             }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className='contest-play-button'>
                    
                    </div>
                    {alertBox && <MsgAlert checkAnsData={checkAnsData}/>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : <LoaderComponent/>
      }
      </>
  )
}
