let headers = {

    'Servicefor': 'inwima',
    'Username':'web',
    'Language': localStorage.getItem("appLang") ? localStorage.getItem("appLang") : 'fr'
}


let data = {
    "calling_code": "Ghana",
    "msisdn": localStorage.getItem("msisdn")

}


const baseUrl = 'https://sandboxapisv1.bngrenew.com/ongoing_inwimo/apn/'
const elastic_base_url = "https://serviceusagesv1.bngrenew.com/publish"



export {headers , data , baseUrl,elastic_base_url}