import React,{useState,useEffect, useContext} from 'react';
import './style/home.css';
import logo from "../components/themeimg/INWI_LOGO.png"
import axios from 'axios';
import { baseUrl, data, headers } from '../config/config';
import icon_destination  from './themeimg/assets/icon_destination.png';
import icon_touristguide from './themeimg/assets/icon_touristguide.png'
import icon_cuisine from './themeimg/assets/icon_cuisine.png';
import icon_culture from './themeimg/assets/icon_culture.png';
import MenuBar from './MenuBar'
import {Link,useNavigate} from 'react-router-dom';
import LoaderComponent from './Loader';
import headerImage from './themeimg/assets/logo.png'
import { Context } from '../Context/Context';
import Lang from './Lang'
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';






const ImgMapping = {'Destination':icon_destination,'Tour guide':icon_touristguide,'Kitchen':icon_cuisine,'Culture':icon_culture};
const Home = (props) => {

    const navigate = useNavigate();
    const[loader,setLoader] = useState();
    const [openHam,setHamburger] = useState(true);
    const configData = props.configData;
    const [showPlayBtn,SetShowPlayBtn] = useState(false);
    // const [pageLoader,setPageLoader] = useState(false)
    const {pageLoader , handlePageLoader} = useContext(Context)
    const [contestApiData,setContestApiData] = useState();
    const [openLang,setOpenLang] = useState(false)
    const lang = localStorage.getItem("appLang")

    useEffect(() => {
        let guiEvents={};
    guiEvents['page']="home";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);

        getAvailCont();
    },[])

    console.log("contest api data --> ", contestApiData)

    const randomObject = {"key1": "value1", "key2": "value2", "key3": "value3"};

    // const onClickHandler = (value) => {
    //     navigate('/scoreboard', { state: { loader: value } })
    //     // navigate('/scoreboard', { state: "random string" })
    // }
    // console.log("randomObject", randomObject)

    const getAvailCont = async () => {

        const response = await axios({
            method: 'post',
            url: baseUrl+'getAvailableContests',
            data: {...data , "msisdn": localStorage.getItem('msisdn') },
            headers: headers,
        })

        console.log("response --> ",response.data.contests)
        setContestApiData(response.data.contests)
        console.log("score",response.data.contests[0].score)
        localStorage.setItem("Score",response.data.contests[0].score)
        // setPageLoader(false)
    }





    // useEffect(() => {
    //     getHeApiData();
    // },[])



    // const getHeApiData = async () => {
    //     const response = await axios({
    //         method: 'post',
    //         url: 'http://demo.dreamtravelapp.in/api/he',
    //         data: data,
    //         headers: headers,
    //     })
    //     setHeApi(response.data)
    // }

    function  menuListPage(data) {
        sessionStorage.setItem("pageUrl",data.url);
        sessionStorage.setItem("header",data.title);
    }
    const checkSubscriptionApi = async (e) => {
        setLoader(true);
        const response = await axios({
            method: 'post',
            url: baseUrl+'checkSub',
            data: {uid: localStorage.getItem("uid")},
            headers: headers,
        })
        const currentStatus = response.data.subStatus

        if ((response.data.subStatus).toUpperCase() === 'ACTIVE') {
            navigate('/contest')
            
        }else if ((response.data.subStatus).toUpperCase() === 'NEW') {
            navigate('/contest')
           
        }

        else {
            return  window.location.replace(configData.sub_url)
           
        }
        setLoader(false)
        console.log("url",props.configData.menu_list[0].url)
        sessionStorage.setItem("pageUrl",props.configData.menu_list[0].url)
        sessionStorage.setItem("header",props.configData.menu_list[0].title)
    }

    function hamburger(openHam){
        setHamburger(!openHam)
    }
    function imageFullyLoaded(){
        SetShowPlayBtn(true)
    }


    // console.log("image url --> ", props.configData.resources.thumbURL)
    return (
        <>
        <div className='home-page-container'>
            <div className='container theme-bg'>
                {
                     <MenuBar openHam={openHam} setHamburger={setHamburger} drawer_list={props.configData.drawer_list} openLang={openLang} setOpenLang={setOpenLang}/>
                }
                {openLang &&
                    <div className=''>
                         <Lang openLang={openLang} setOpenLang={setOpenLang} />
                    </div>                   
                }
                {pageLoader && <LoaderComponent />}
                <div className='section-home bg-white bg-no-repeat bg-cover'>
                    <div className='container-nav-bar'>
                        <div className='nav-bar'>

                                <div className='hamburger ' onClick={()=>hamburger(openHam)}>
                                    <div className='ham-1'></div>
                                    <div className='ham-1'></div>
                                    <div className='ham-1'></div>
                                </div>

                            {/* <div className='bg-headerImage'></div> */}
                            <img className="w-28" src={headerImage} />
                        </div>
                    </div>

                    {/* below is the code for strip where score should be shown */}
                    <div className='points-class  bg-[#9D328D]'>
                       {contestApiData ?  <p className="has-text-centered has-text-white font-bold ">{txt.score[lang]} : {contestApiData.length > 0 && contestApiData[0] ? contestApiData[0].score : "0"}</p> : <div className='loader-btn'></div>}
                    </div>
                    <div className='img-section'>
                        <figure className='mg'>
                            <img src={localStorage.getItem("thumbUrl")} onLoad={() => {imageFullyLoaded() }}></img>
                        </figure>
                        {showPlayBtn && <div className='text has-text-centered  txt-on-img'>
                                {/* <h5 className='title is-h5 text-white'>{props.configData.name}</h5> */}
                                <div className='button is-primary is-rounded is-uppercase' onClick={(e) =>checkSubscriptionApi(e)}>{loader && <div className='loader-btn'></div>}{ !loader &&  props.configData.subStatusMsg}</div>
                            </div>
                        }
                    </div>
                    {/* below code is to check navigate state pass between routes */}
                    {/* <div onClick={() => onClickHandler(randomObject)} className='border-2 border-black'>
                        click me
                    </div> */}
                    <div>
                        <div className='menu-list theme-bg'>
                                {
                                    props.configData.menu_list &&  props.configData.menu_list.map((data,key) => {
                                        return (
                                            <div className='list-items' key={key}>
                                                <div >
                                                    <Link onClick={()=> menuListPage(data)} to={`/menuList/${(data.title).replaceAll(" ","")}`} >
                                                        <div>
                                                            <figure>
                                                                <img  src={ImgMapping[data.menu_id]} alt="" />
                                                                    <figcaption>{data.title}</figcaption>
                                                            </figure>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}
export default Home;
