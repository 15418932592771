import React, { useContext, useState } from 'react'
import { Context } from '../Context/Context'
import axios from 'axios'
import { baseUrl, headers } from '../config/config'
import headerImage from './themeimg/assets/logo.png'
import LoaderComponent from './Loader';
import { txt } from './text'
import { Navigate } from 'react-router-dom'
import { SendGuiDataEvents } from '../CommonScript'

function SubModal() {

    const {subModalData , handleSubModal, handlePageLoader, toggleSubModal} = useContext(Context)
    // const navigate = useNavigate()
    const [loaderMainScreen,setLoaderMainScreen] = useState(false);
    const lang = localStorage.getItem("appLang")
    
    const checkSubscriptionApi = async () => {
        // setLoader(true);
        setLoaderMainScreen(true)
        const response = await axios({
            method: 'post',
            url: baseUrl+'checkSub',
            data: {uid: localStorage.getItem("uid")},
            headers: headers,
        })

        setLoaderMainScreen(false)
        if ((response.data.subStatus).toUpperCase() === 'ACTIVE') {
            // navigate('/home')
            window.open('/home',"_self")
        }else if ((response.data.subStatus).toUpperCase() === 'NEW') {
            // navigate('/contest')
            window.open(localStorage.getItem("subUrl"),"_self")
        }

        else {
            // return  window.location.replace(configData.sub_url)
            window.open(localStorage.getItem("subUrl"),"_self")
        }
        // setLoader(false)

    }

    const unlockApi =  async () => {
      // setLoader(true);
      setLoaderMainScreen(true)
      try{
      const response =  await axios({
          method: 'post',
          url: baseUrl+'unlock',
          data: {msisdn: localStorage.getItem("msisdn")},
          headers: headers,
      })
      
      const Status = response.data.status
      if ((response.data.status).toUpperCase() === 'SUCCESS') {
          // navigate('/home')
          
          let guiEvents={};
                  guiEvents['page']="unlock";
                  guiEvents["event"] = "success";
                  guiEvents["status"] = Status;
                  await SendGuiDataEvents(guiEvents);
                   window.open('/congrats',"_self")
      }

      else {
          // return  window.location.replace(configData.sub_url)
          let guiEvents={};
                  guiEvents['page']="unlock";
                  guiEvents["event"] = "fail";
                  guiEvents["status"] = Status;
                  await SendGuiDataEvents(guiEvents);
          window.open("/unlockfail","_self")
      }
      setLoaderMainScreen(false)
    }
    catch (error) {
      console.error('An error occurred while unsubscribing:', error);
      setLoaderMainScreen(false)
    }
      // setLoader(false)

  }

  const unlock =()=>{
     unlockApi()
  }

  const closeModal = (value) => {
    if (subModalData.btn.length > 1 && value === '1') {
    //   onHandleUnsub(uid)
    toggleSubModal(false)
        console.log("Yes btn clicked")
    } else if (subModalData.btn.length > 1 && value === '2') {
      console.log("No btn clicked")
    } else {
      console.log("Okay btn clicked")
      toggleSubModal(false)
      console.log("subModalData ------->",subModalData)
      if(subModalData.id === 'unsub'){
        // checkSub(uid)
        checkSubscriptionApi()
        console.log("unsub clicked")
      }else if(subModalData.id === 'warning'){
        // window.open(redirect_url,"_self")
        toggleSubModal(false)
      }
    }
    console.log("close modal clicked")
    handleSubModal(subModalData,false);
  }

  const gotoUnlock = () => {
    // Navigate('/home');
    window.open("/unlock","_self")
  }

    return (
        <div className='absolute w-full bg-gray-900 max-w-[500px] h-full right-0 z-50 my-0 mx-auto left-0 bg-green_bg_pic z-60'>
          {loaderMainScreen ? <LoaderComponent /> :
            <div className='w-full absolute bg-white bottom-0 left-0 rounded-xl right-0 mx-auto border-4 border-gray-800 '>
            {/* <div className='text-right text-4xl pr-2' onClick={closeModal}>&times;</div> */}
            <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
                  <div className='back-arrow back-btn-quiz' onClick={() => gotoUnlock()}>
                        <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
                      </div>
                    {/* <h5 className='has-text-black has-text-centered '>DREAM TRAVEL</h5> */}
                    <img className="w-28 relative right-6 mx-auto" src={headerImage} />
                  </div>
            <div className='font-bold pt-6 text-center text-xl w-[70%] mx-auto'>{subModalData.body}</div>
            <div className='pb-4 pt-3 text-center px-10'>
                {/* <p className='text-xl'>{subModalData.body}</p> */}
                <p className='text-2xl text-[#9D328D] font-bold'>{txt.week1[lang]}</p>
                <p className='mt-2  py-2 rounded-full font-bold text-white  bg-[#9D328D]  bg-darkGreen shadow-xl cursor-pointer'  onClick={unlock}>{subModalData.btn[0]}</p>
                {subModalData.btn.length > 1 && <p className='font-bold mt-2 py-2 rounded bg-[#607AF1]  text-white  bg-bgGreenIslam border'  onClick={() =>closeModal("2")}>{subModalData.btn[1]}</p>}
            </div>
        </div>}
        </div>

      )
}

export default SubModal