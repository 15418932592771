import React from "react";
import { txt } from "./text";

function ScoreModal ({score,setShowScore,showScore}) {
   const lang = localStorage.getItem("appLang")
    return (
        
        <div className='fixed w-full bg-gray-900 max-w-[500px] h-full bottom-0 right-0 z-50 my-0 mx-auto left-0 bg-green_bg_pic z-60'>
        
          <div className='absolute  bottom-[50%] top-20 left-0 rounded-xl right-0 border-4 border-gray-800 w-[70%] mx-auto h-[65%] '>
          
          {/* <div className='font-bold pt-6 text-center text-xl'>{subModalData.title}</div> */}
          <div className='pb-4 pt-3 text-center px-10 bg-scorepopup bg-cover bg-no-repeat h-[95%]'>
              {/* <p className='text-xl'>{subModalData.body}</p> */}
              <p className='text-3xl text-[#30123E] font-bold'>{txt.hurray[lang]}</p>
              <p className='text-xl text-[#88879C] '>{txt.inching[lang]}</p>
              <div className="w-[60%] mx-auto h-40 bg-[]  ring-offset-2 ring-4 ring-[#88879C] rounded-2xl">
                   <p className="text-xl">{txt.yourscore[lang]}</p>
                   <p className="text-5xl text-[#AD539F] font-bold">{score ? score : "--"}</p>
              </div>
              
              {/* <p className='mt-2  py-2 rounded-full font-bold text-white  bg-[#9D328D]  bg-darkGreen shadow-xl cursor-pointer'  onClick={unlock}>{subModalData.btn[0]}</p>
              {subModalData.btn.length > 1 && <p className='font-bold mt-2 py-2 rounded bg-[#607AF1]  text-white  bg-bgGreenIslam border'  onClick={() =>closeModal("2")}>{subModalData.btn[1]}</p>} */}
          </div>
          <div className="pt-2 bg-white text-center h-[15%]">
                <p className="font-bold text-[#30123E] cursor-pointer w-[100%]" onClick={()=>setShowScore(false)}>{txt.ok[lang]}</p>
              </div>
      </div>
      </div>
    )
}

export default ScoreModal