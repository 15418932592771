import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import headerImage from './themeimg/assets/logo.png'
import modal from "./themeimg/assets/modalArt.png"
import { baseUrl, data, headers } from '../config/config';
import axios from 'axios';
import LoaderComponent from './Loader';
import ScoreModal from './ScoreModal'
import { txt } from "./text";
import { SendGuiDataEvents } from "../CommonScript";

function QuizModal () {

    const [data,setData] = useState()
    const [loaderMainScreen,setLoaderMainScreen] = useState(false);
    const [score,setScore] = useState()
    const [showScore,setShowScore] = useState(false)
    const lang = localStorage.getItem("appLang")
    const [contestApiData,setContestApiData] = useState();
    // const score = localStorage.getItem("Score")

    // const getAvailableLevelsForUnlockApi = async () => {
    //     // data['msisdn']= msisdn;
    //     setLoaderMainScreen(true)
    //     const response = await axios({
    //         method: 'post',
    //         url: baseUrl+'getAvailableLevelsForUnlock',
    //         data: {...data , msisdn: localStorage.getItem('msisdn')},
    //         headers: headers,
    //     })
    //     setData(response.data);
    //     setLoaderMainScreen(false)
    //     console.log("count",response.data.count)
    //     // setScore(response.data.score)
    //     // localStorage.setItem("Score",response.data.score)
    //   }

      const getAvailCont = async () => {

        const response = await axios({
            method: 'post',
            url: baseUrl+'getAvailableContests',
            data: {...data , "msisdn": localStorage.getItem('msisdn') },
            headers: headers,
        })

        console.log("response --> ",response.data.contests)
        setContestApiData(response.data.contests)
        console.log("score",response.data.contests[0].score)
        localStorage.setItem("Score",response.data.contests[0].score)
        setScore(response.data.contests[0].score)
        // setPageLoader(false)
    }

    const getAvailableLevelsForUnlockApi = async () => {
      // data['msisdn']= msisdn;
      setLoaderMainScreen(true)
      const response = await axios({
          method: 'post',
          url: baseUrl+'getAvailableLevelsForUnlock',
          data: {...data , msisdn: localStorage.getItem('msisdn')},
          headers: headers,
      })
      setData(response.data);
      setLoaderMainScreen(false)
      console.log("data",response.data.status)
      if(response.data.status === 'SUCCESS' ){
          window.open("/unlock","_self")
      }
      else{
          window.open("/unlockmodal","_self")
      }
    }

    const openHome = () => {
        window.open("/home","_self")
      }

      const openUnlock =()=>{
        // window.open("/unlock","_self")
        getAvailableLevelsForUnlockApi()
      }

      const showScores =()=>{
        setShowScore(true)
      }

      useEffect(()=>{
        // getAvailableLevelsForUnlockApi()
        getAvailCont()
        let guiEvents={};
            guiEvents['page']="result";
            guiEvents["event"] = "open";
             SendGuiDataEvents(guiEvents);
      },[])

    return(
        <div className="container ">
             {showScore &&
            <div>
                 <ScoreModal score={score} showScore={showScore} setShowScore={setShowScore} />
            </div>
             }
        <div className="background-theme">
        {loaderMainScreen ? <LoaderComponent /> :
          <div className='contest-container '>
           
        <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
                  <div className='back-arrow back-btn-quiz' onClick={openHome}>
                        <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
                      </div>
                      <img className="w-28 relative right-6 mx-auto" src={headerImage} />
                      </div>
                      <div className='bottom-white-bg bg-[#FAFAFA] bg-cover bg-no-repeat relative pt-12'>
                        <div className="bg-white pt-10 w-[85%] mx-auto h-[95%]">
                            <div>
                            <img className="w-32 mx-auto" src={modal} />
                            </div>
                            <div className="text-center pt-4 w-[90%] mx-auto">
                                <p className="text-[#481B68] font-bold text-2xl">{txt.folks[lang]}</p>
                                <p className="text-[#3B3B3B] font-semibold text-xl">{txt.completedquestions[lang]}</p>
                                <p className="text-[#6B4685] text-base">{txt.youcanchk[lang]}</p>
                            </div>
                            <div className="text-center pt-5 w-[80%] mx-auto cursor-pointer">
                                <button className="bg-[#9D328D] text-white w-full py-1 text-xl rounded-2xl" onClick={openUnlock}>{txt.unlockmorequestion[lang]}</button>  
                            </div>
                            <div className="text-center pt-1 w-[80%] mx-auto cursor-pointer">
                            <button className=" text-[#9D328D] border-2 border-[#9D328D] w-full py-1 text-xl rounded-2xl" onClick={showScores} >{txt.checkscorebtn[lang]}</button>
                        </div>
                        </div>
                      </div>
        
        </div>}
        </div>
        </div>
    )
}

export default QuizModal