import React,{useState,useEffect, useContext} from 'react';
import './style/welcomePage.css';
import LoaderComponent from './Loader';
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { baseUrl, data, headers } from '../config/config';
import queryString from 'query-string';
import backgroundImage from "../components/themeimg/assets/welcomeBg.png"
import headerImage from "../components/themeimg/assets/logo.png"
import { SendGuiDataEvents, sendWapRedirection } from '../CommonScript';




const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}




const WelcomePage = (props) => {
  const headerImg = require("../components/themeimg/assets/logo.png")

    const [bntLoader,setBtnLoader] = useState(false)

    // var baseUrl = props.baseUrl
    var apiData = props.apiData;
    localStorage.setItem("subUrl",apiData.sub_url)

    var loader = props.loader;
    var introPageData = props.introPageData;
    const navigate = useNavigate();
    const gotoHome = () => {
        navigate('/home');
        setBtnLoader(true);
        // checkSubscriptionApi();
    }


// getting uid

let location = useLocation();
let params = queryString.parse(location.hash)
console.log("params", params)
console.log("params.uid", params["/?uid"])
const uid = params["/?uid"]
// const uid = Object.values(params).toString("")
console.log("uid", uid)




let query = useQuery();

const userId = query.get("userId")

console.log("userId ---> ", userId)



const checkSubHandler = async (uid) => {
  try {
    const resp = await axios({
      method: 'post',
      url: baseUrl + 'checkSub',
      data: { uid: uid },
      headers: headers,
    });

    const currentStatus = resp.data.subStatus;

    if (resp.data.status !== '' && resp.data.status !== undefined) {
      if (resp.data.status === 'failed') {
        await sendWapRedirection('subscription', 'sub_redirect', apiData.sub_url,"failed");
      } else if (resp.data.status === 'success') {
        if (resp.data.subStatus === 'new') {
          await sendWapRedirection('subscription', 'sub_redirect', apiData.sub_url,currentStatus);
        } else if (resp.data.subStatus === 'active') {
          localStorage.setItem("msisdn", resp.data.msisdn);
          localStorage.setItem("status", resp.data.status);
          localStorage.setItem("subStatus", resp.data.subStatus);

          let guiEvents = {
            page: "subscription",
            status: currentStatus,
            event: "subscribed_user",
            status: currentStatus
          };
          await SendGuiDataEvents(guiEvents);
          navigate('/home');
        } else if (resp.data.subStatus === 'inactive') {
          await sendWapRedirection('subscription', 'sub_redirect', apiData.sub_url , currentStatus);
        } else {
          await sendWapRedirection('subscription', 'sub_redirect', apiData.sub_url, currentStatus);
        }
      }
    }
    localStorage.setItem("subStatus", resp.data.subStatus);
  } catch (error) {
    console.error('An error occurred while checksub:', error);
  }
}





      function onClickHandler() {
        setBtnLoader(true);
        if (uid !== '' && uid !== undefined && uid !== null) {
          localStorage.setItem("uid", uid);
          checkSubHandler(uid)
        } else {
          if (localStorage.getItem("uid") !== '' && localStorage.getItem("uid") !== undefined && localStorage.getItem("uid") !== null) {
            checkSubHandler(localStorage.getItem("uid"))
          } 
          else {
            console.log("redirect ---> app.js")
            // redirect to subUrl
            let guiEvents={};
    guiEvents['page']="landingPage";
    guiEvents["event"] = "redirect";
    guiEvents['redirected_url'] = apiData.sub_url
    SendGuiDataEvents(guiEvents);
            window.open(apiData.sub_url, "_self")
            if(!apiData.sub_url && !uid){
              window.open('/error', "_self")
            }
            // navigate('/sub')
            console.log("redirect")
          }
        }
      }

      useEffect(() => {
        let guiEvents={};
    guiEvents['page']="landingPage";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);
    },[])




    return (
        <>
            <div>
                <div>
                    <div className="container h-screen">
                      <div><img className="w-28 mx-auto" src={headerImg} alt="header"/></div>
                        {loader && <LoaderComponent/>
                        }
                        {/* previous wallpaper for the div below */}
                        {/* style={{backgroundImage:`linear-gradient(rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),url(${introPageData.img_url})`}} */}
                        {!loader && <div className="theme-bg bg-cover  bg-white bg-no-repeat"  >
                            <img src={backgroundImage} />
                            <div className="welcome-page-text has-text-centered absolute bottom-0 mb-2 px-1">
                                <div className="columns text-black">
                                    <h5 className="title text-black">{introPageData.title}</h5>
                                    <p>{introPageData.desc}</p>
                                    <a className="button mx-auto is-warning is-fullwidth m-t-10 " href='#home' onClick={() => onClickHandler()} >{bntLoader && <div className='loader-btn'></div>}{!bntLoader && apiData.button_text}</a>
                                </div>

                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default WelcomePage;