import React, { useEffect, useState } from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { baseUrl, data, headers } from '../config/config';
import LoaderComponent from './Loader';
import './style/contest.css';
import iconSet from './themeimg/assets/Quizstart.png';
import headerImage from './themeimg/assets/logo.png'
import axios from 'axios';
import { txt } from './text';
import QuizModal from './QuizModal';


export default function ContestWelcome(props) {
  const resource = props.configData.contest_rules;
  console.log("resources ----> ", resource)
  const navigate = useNavigate();
  const [nextQue,setNextQue] = useState();
  const [availableLevel,setAvailableLevel] =  useState();
  const [stateSendObj,setStateSendObj] =  useState({ nextQue: "", availableLevel: "" });
  const [bntLoader,setBtnLoader] = useState(false)
  const [pageLoader,setPageLoader] = useState(true)
  const lang = localStorage.getItem("appLang")
  const url = sessionStorage.getItem("pageUrl")
  const [dataRecieved,setDataRecieved] = useState(false)

  // api parameters

  // var baseUrl = props.baseUrl

  useEffect(()=>{
    getNextQuestionApi()
  },[])


  //get question from api
  const getNextQuestionApi = async () => {
    const response = await axios({
        method: 'post',
        url: baseUrl+'getNextQuestion',
        data: {...data ,"msisdn": localStorage.getItem("msisdn")},
        headers: headers,
    })
    setNextQue(response.data);
    console.log("respone for getquestions",response.data)
    console.log("dataques",nextQue)
    setStateSendObj(prevState => ({ ...prevState, ['nextQue']:response.data}));
    console.log("data",stateSendObj)

    if (response.data.subStatus === "EndOfQuestion") {
      getAvailableLevelsForUnlockApi();
    }else{
      setPageLoader(false)
    }
}
const getAvailableLevelsForUnlockApi = async () => {
  // data['msisdn'] = sessionStorage.getItem('msisdn');
  const response = await axios({
      method: 'post',
      url: baseUrl+'getAvailableLevelsForUnlock',
      data: {...data , "msisdn": localStorage.getItem("msisdn")},
      headers: headers,
  })
  setAvailableLevel(response.data);
  console.log("response for levels",response.data)
  setStateSendObj(prevState => ({ ...prevState, ['availableLevel']:response.data}));
  console.log("stateobject",stateSendObj)
  setPageLoader(false)
}
const openQuiz = () => {
 window.open("/unlockmodal","_self")
}

const redirectToQuiz = () => {
  {stateSendObj && console.log("dataques",stateSendObj.nextQue)}
   if(stateSendObj){
    setDataRecieved(true)
   }
  if (stateSendObj?.nextQue !== "") {
    navigate('/quiz', { state: stateSendObj });
  }
};

useEffect(() => {
  //  getNextQuestionApi();

    
    const timeoutId = setTimeout(() => {
      redirectToQuiz();
    }, 5000);

    return () => clearTimeout(timeoutId);

},[dataRecieved]);



  //conso le.log("stateSendObj=>",stateSendObj)
  const gotoHome = () => {
    navigate('/home');
  }
  const openUrl = ()=>{
    navigate("/menuList/Destination")
  }
 //console.log("resource::",resource)
  return (
      <>
        <div>
          <div>
            <div className="container">
              <div className="background-theme">
                <div className='contest-container'>
                  <div className='upper-yellow-bg  bg-white flex items-center justify-center backdrop-blur-sm'>
                  <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
                      </div>
                    {/* <h5 className='has-text-black has-text-centered '>DREAM TRAVEL</h5> */}
                    <img className="w-28 relative mx-auto right-5" src={headerImage} />
                  </div>
                  {pageLoader && <LoaderComponent />}
                  <div className='bottom-white-bg bg-[#F8F8F8] bg-cover pt-16 bg-no-repeat'>
                    <div className={nextQue && nextQue.subStatus === 'EndOfQuestion'? 'card card-height ':'card'}>
                    {/* {nextQue && nextQue.subStatus === 'EndOfQuestion' &&
                     <div className='px-2 py-4 bg-white h-[11rem] mt-[2rem] text-[#607AF1] font-bold flex items-center bg-no-repeat bg-cover'>
                                <div className='w-full font-bold text-4xl text-center text-black'> {txt.welcome[lang]}</div>
                            </div> } */}
                      {  nextQue && nextQue.subStatus === 'EndOfQuestion' ? 
                      // <div className='card-container has-text-centered'>{

                      //  <div className='end-of-que-class'>
                      //     {
                      //        availableLevel && availableLevel.msg
                      //     }
                      //   </div>}

                      //   </div>
                      openQuiz() :
                        <div className='card-container has-text-centered'>
                          <div className='card-head font-extrabold '>{resource && resource.header}</div>
                          <div className='card-center-div'>
                            <div className='pt-8'>{resource && resource.heading}</div>
                            <img className='mx-auto pb-2' src={iconSet} alt='imag'/>
                            
                          </div>
                          <p className='card-para font-bold'>{resource && resource.rules.step2}</p>
                          <p className=' mb-6 font-thin text-[#481B68]'>{txt.tensectxt[lang]}</p>
                          <div className='card-last-sec cursor-pointer underline' onClick={openUrl}>{resource && resource.footer}</div>
                      </div>
                      }

                    </div>
                    <div className='contest-play-button'>
                      {console.log("data",stateSendObj.nextQue)}
                      {stateSendObj.nextQue !="" &&

                      <Link className="button play-btn shadow-2xl" to={"/quiz"} state={stateSendObj}>{ txt.playnow[lang]}</Link>
                      // <Link className="button play-btn shadow-2xl">{txt.playnow[lang]}</Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
