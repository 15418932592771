import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import logo from "../components/themeimg/INWI_LOGO.png"
import { baseUrl, data, headers } from '../config/config';
import Loader from './Loader';
import './style/scoreboard.css'


export default function Scoreboard({ }) {

    const [sbData, setSbData] = useState([])
    const [loader, setLoader] = useState(true)
    // var baseUrl = "http://dev.dreamtravelapp.in/api/";
    const navigate = useNavigate()
    var header = sessionStorage.getItem("header");


 {/* below code is to check navigate state pass between routes */}

    // const {state} = useLocation()

    // const {loader: value} = state;

    // console.log("value", value)

        function changeToStar(num) {
        const temp = num.split('')
        for (let i = 0; i < temp.length - 3; i++) {
            temp[i] = "*"
        }
        let ans = temp.join('')

        return ans


    }




    const gotoHome = () => {
        navigate('/home');
    }



    const scoreBoard = async () => {
        const response = await axios({
            method: 'post',
            url: baseUrl + 'topScore',
            headers: headers,
        })

        setSbData(response.data)
        setLoader(false)
    }

    useEffect(() => {
        scoreBoard()
    }, [])



    return (
        <>
            {(loader ? <Loader /> :
                <div className='scoreboard-container '>
                    <div className='container scoreboard-bg'>
                        <div className='section-home'>
                            <div className='container-nav-bar'>
                                <div className='nav-bar border-2'>
                                    <div className='back-arrow'>

                                        <div className='back-arrow-button' onClick={() => gotoHome()}>
                                            <p className="arrow left"></p>
                                        </div>
                                    </div>

                                    <div className='header-hosted-page'>Scoreboard</div>
                                    {/* <div className='logo-div'><img className='logo' src={logo} /> </div> */}

                                </div>
                            </div>


                            <div className='hosted-page-body'>
                                <div className='flex padding-2 header-text'>
                                    <div className=''>RANK</div>
                                    <div className=''>SCORE</div>
                                    <div className=''>MSISDN</div>
                                </div>

                                {sbData.map((data, index) => {
                                    return (<>



                                        <div className=' grid padding-2 '>
                                            <div className=' content-text '>{index + 1}</div>
                                            <div className=' content-text content-center'>{data.score}</div>
                                            <div className=' content-text '>{changeToStar(data.userId)}</div>
                                        </div>


                                    </>)
                                })}



                            </div>



                        </div>
                    </div>
                </div>)}
        </>
    )
}
