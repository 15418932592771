import React,{useState,useEffect} from 'react'
import {Link, Navigate, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { baseUrl, data, headers } from '../config/config';
import LoaderComponent from './Loader';
import { txt } from './text';

export default function MenuBar(props) {
    const [drawerList,setDrawerList] = useState([]);
    const [data,setData] = useState()
    const [loaderMainScreen,setLoaderMainScreen] = useState(false);
    const lang = localStorage.getItem("appLang")
    const navigate = useNavigate()
    useEffect(()=>{
        setDrawerList(props.drawer_list);
    },[props.drawer_list])

    function hideMenuBar() {
       props.setHamburger(true)
    }

    function  menuListPage(data) {
        sessionStorage.setItem("pageUrl",data.url);
        sessionStorage.setItem("header",data.title);
    }
    const msisdn = sessionStorage.getItem("msisdn")


    
    const getAvailableLevelsForUnlockApi = async () => {
        // data['msisdn']= msisdn;
        setLoaderMainScreen(true)
        const response = await axios({
            method: 'post',
            url: baseUrl+'getAvailableLevelsForUnlock',
            data: {...data , msisdn: localStorage.getItem('msisdn')},
            headers: headers,
        })
        setData(response.data);
        setLoaderMainScreen(false)
        console.log("data",response.data.status)
        if(response.data.status === 'SUCCESS' ){
            window.open("/unlock","_self")
        }
        else{
            window.open("/unlockmodal","_self")
        }
      }
    

    const scoreBoardHandler = () => {
        navigate("/scoreboard")
    }

    const unsubHandler = () => {
        navigate("/unsub")
    }

    const unlockQuestionsHandler = () => {
        // navigate("/unlock")
        getAvailableLevelsForUnlockApi()
    }

    const galleryHandler = () => {
        navigate("/gallery")
    }
    const langHandler = () => {
        // navigate("/lang")
        props.setOpenLang(true)
        hideMenuBar()
    }
    return (
        <div id='mySidenav' className={props.openHam?'menu-bar ':'menu-bar active'}>
               
                <div className='sidebar-heading relative sidebar-heading-title mb-4 bg-[#9D328D] bg-cover h-10  bg-no-repeat'>
                <a href="#" className="close " onClick={() => {hideMenuBar()}}></a>
                        {/* image */}


                            <div className=' h-full w-full flex flex-row items-center justify-center'>
                                    <div className=' w-full text-white pt-2'>  {txt.menu[lang]}  </div>
                            </div>


                    </div>

                    {loaderMainScreen ? <LoaderComponent/>:
            <div className='link-tags' dir={lang === 'ar' ? 'rtl' : 'ltr'}>
                {
                  drawerList && drawerList.map((item,ind) => {

                        if(ind === 1){
                            // return (
                            //     <div className='my-2 cursor-pointer'>
                            //     <div className='my-0 nav-link-single text-white' key={ind}   onClick={()=> scoreBoardHandler()}>{item.title}</div>
                            //     <hr />
                            //     </div>
                            // )
                            return null;
                        }else if(ind === 4){
                            return (
                                <div className='my-2 cursor-pointer'>
                                <div className='my-0 nav-link-single text-white' key={ind}   onClick={()=> unsubHandler()}>{item.title}</div>
                                <hr />
                                </div>
                            )
                        }else if(ind === 0){
                            return (
                                <div className='mt-2 cursor-pointer'>
                                <div className='my-2 nav-link-single text-white' key={ind}   onClick={()=> unlockQuestionsHandler()}>{item.title}</div>
                                <hr />
                                </div>
                            )
                        }else if(ind === 2){
                            return (
                                <div className='my-2 cursor-pointer'>
                                <div className='my-2 nav-link-single text-white' key={ind}   onClick={()=> galleryHandler()}>{item.title}</div>
                                <hr />
                                </div>
                            )
                        }
                        else if(ind === 6){
                            return (
                                <div className='my-2 cursor-pointer'>
                                <div className='my-2 nav-link-single text-white' key={ind}   onClick={()=> langHandler()}>{item.title}</div>
                                <hr />
                                </div>
                            )
                        }
                        else{

                               return ( <div className='my-2 cursor-pointer'>
                                <Link className='my-2 nav-link-single text-white ' to={`/navlist/${item.title.replaceAll(" ","")}`} key={ind}   onClick={()=> menuListPage(item)}>{item.title}</Link>
                                <hr />
                                </div>
                                )
                        }
                  })
                }
            </div>}

        </div>
    )
}
