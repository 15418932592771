import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { baseUrl, data, headers } from '../config/config';
import editImg from "../components/themeimg/assets/editImage.png"
import { useNavigate } from 'react-router-dom';
import LoaderComponent from './Loader';
import { Context } from '../Context/Context';
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';

function GoToSub() {
    const [inputState, setInputState] = useState()
    const {handleModal , pageLoader , handlePageLoader} = useContext(Context)
    const currentUserMsisdn = localStorage.getItem("msisdn")
    // const [pageLoader,setPageLoader] = useState(false)
    const handle = useRef(null)
    const navigate = useNavigate()
    const lang = localStorage.getItem("appLang")


    const handleFocus = () => {
        handle.current && handle.current.focus()
      }


      const checkSubscriptionApi = async () => {
        // setLoader(true);
        const response = await axios({
            method: 'post',
            url: baseUrl+'checkSub',
            data: {uid: localStorage.getItem("uid")},
            headers: headers,
        })

        handlePageLoader(false)
        if ((response.data.subStatus).toUpperCase() === 'ACTIVE') {
            navigate('/home')
        }else if ((response.data.subStatus).toUpperCase() === 'NEW') {
            // navigate('/contest')
            window.open(localStorage.getItem("subUrl"),"_self")
        }

        else {
            // return  window.location.replace(configData.sub_url)
            window.open(localStorage.getItem("subUrl"),"_self")
        }
        // setLoader(false)

    }

    



//     const unsubReqeustHandler = () => {

      
//       handlePageLoader(true)
//       axios({
//           url: baseUrl + '/unsubscribe',
//           method: 'post',
//           // data: {
//           //     "msisdn": String(),
//           //     "calling_code": "Ghana",
//           //     "serviceId": ""
//           // },
//           data: { msisdn: localStorage.getItem('msisdn')},
//           headers: headers
//       }).then((resp) => {
//         handlePageLoader(false)
//           if (resp.data.status === 'failed') {
//               // const ModalData = {
//               //   title : "Oops",
//               //   body : `${resp.data.message}`,
//               //   btn : ["Okay"],
//               //   id: "unsub"
//               // }
//               // console.log("api failed")
//               // handleModal(ModalData, true)
//               window.open('/unsubfail','_self');

//           } else if (resp.data.status === 'success') {
//               console.log("api successful")
//               // const ModalData = {
//               //   title : "Bravo",
//               //   body : `${resp.data.message}`,
//               //   btn : ["Okay"],
//               //   id: "unsub"
//               // }
//               // handleModal(ModalData, true)
//               window.open(localStorage.getItem("subUrl"),"_self")
//           }
//       })
    




//       // .finally(() => {
//       //   checkSubscriptionApi()
//       //   }
//       // )
// }

  const gotoHome = () => {
    window.open('/home','_self');
  }

  const ShowSubPage = () =>{
    let guiEvents={};
    guiEvents['page']="unsub";
    guiEvents["event"] = "redirect";
    guiEvents["redirected_url"] = localStorage.getItem("subUrl");
     SendGuiDataEvents(guiEvents);
    window.open(localStorage.getItem("subUrl"),"_self")
    // unsubReqeustHandler()
  }


    return (
        <div className='max-w-[500px] mx-auto  h-[100vh]'>
            {pageLoader && <LoaderComponent />}
            <div className='upper-yellow-bg bg-white backdrop-blur-sm pt-4'>
                  <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
                      </div>
                    <h5 className='has-text-black has-text-centered mt-2'>{txt.unsub[lang]}</h5>
                  </div>
        <div className='bg-Sub w-[90%] mx-auto mt-8 h-[14rem] bg-cover bg-center bg-no-repeat '></div>
        <div className='my-2 font-bold mx-auto w-[80%] text-lg text-center pt-10'> {txt.srymsg[lang]}</div>
       <div className='pt-10'> <div className='button is-unsub mx-auto' onClick={ShowSubPage}><button>{txt.gobacktosub[lang]}</button></div></div>   
              </div>
    )
}

export default GoToSub