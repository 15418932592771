import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import headerImage from './themeimg/assets/logo.png'
import modal from "./themeimg/assets/modalArt.png"
import { txt } from "./text";
import ScoreModal from "./ScoreModal";

function UnlockModal () {
    const lang = localStorage.getItem("appLang")
    const score = localStorage.getItem("Score")
    const [showScore,setShowScore] = useState(false)
    const gotoHome = () => {
        Navigate('/home');
      }

      const openHome =()=>{
        window.open("/home","_self")
      }

      const showScores =()=>{
        setShowScore(true)
      }

    return(
        <div className="container ">
        <div className="background-theme">
        {showScore &&
            <div>
                 <ScoreModal score={score} showScore={showScore} setShowScore={setShowScore} />
            </div>
             }
          <div className='contest-container '>
        <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
                  <div className='back-arrow back-btn-quiz' onClick={openHome}>
                        <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
                      </div>
                      <img className="w-28 relative right-6 mx-auto" src={headerImage} />
                      </div>
                      <div className='bottom-white-bg bg-[#FAFAFA] bg-cover bg-no-repeat relative pt-12'>
                        <div className="bg-white pt-10 w-[85%] mx-auto h-[95%]">
                            <div>
                            <img className="w-32 mx-auto" src={modal} />
                            </div>
                            <div className="text-center pt-4 w-[90%] mx-auto">
                                <p className="text-[#481B68] font-bold text-2xl">{txt.folks[lang]}</p>
                                <p className="text-[#3B3B3B] font-semibold text-xl">{txt.exhaustedtodaylimit[lang]}</p>
                                <p className="text-[#6B4685] text-base">{txt.youcanchk[lang]}</p>
                            </div>
                            <div className="text-center pt-5 w-[80%] mx-auto cursor-pointer">
                                <button className="bg-[#9D328D] text-white w-full py-1 text-xl rounded-2xl" onClick={openHome}>{txt.home[lang]}</button>  
                            </div>
                            <div className="text-center pt-1 w-[80%] mx-auto cursor-pointer">
                            <button className=" text-[#9D328D] border-2 border-[#9D328D] w-full py-1 text-xl rounded-2xl" onClick={showScores} >{txt.scoreboard[lang]}</button>
                        </div>
                        </div>
                      </div>
        
        </div>
        </div>
        </div>
    )
}

export default UnlockModal