import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { baseUrl, data, headers } from '../config/config';
import editImg from "../components/themeimg/assets/editImage.png"
import { useNavigate } from 'react-router-dom';
import LoaderComponent from './Loader';
import { Context } from '../Context/Context';
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';

function Unsub() {
    const [inputState, setInputState] = useState()
    const {handleModal , pageLoader , handlePageLoader} = useContext(Context)
    const currentUserMsisdn = localStorage.getItem("msisdn")
    // const [pageLoader,setPageLoader] = useState(false)
    const handle = useRef(null)
    const navigate = useNavigate()
    const lang = localStorage.getItem("appLang")


    const handleFocus = () => {
        handle.current && handle.current.focus()
      }


      const checkSubscriptionApi = async () => {
        // setLoader(true);
        const response = await axios({
            method: 'post',
            url: baseUrl+'checkSub',
            data: {uid: localStorage.getItem("uid")},
            headers: headers,
        })

        handlePageLoader(false)
        if ((response.data.subStatus).toUpperCase() === 'ACTIVE') {
            navigate('/home')
        }else if ((response.data.subStatus).toUpperCase() === 'NEW') {
            // navigate('/contest')
            window.open(localStorage.getItem("subUrl"),"_self")
        }

        else {
            // return  window.location.replace(configData.sub_url)
            window.open(localStorage.getItem("subUrl"),"_self")
        }
        // setLoader(false)

    }

    



  //   const unsubReqeustHandler = () => {

      
  //       handlePageLoader(true)
  //       axios({
  //           url: baseUrl + '/unsubscribe',
  //           method: 'post',
  //           // data: {
  //           //     "msisdn": String(),
  //           //     "calling_code": "Ghana",
  //           //     "serviceId": ""
  //           // },
  //           headers: headers
  //       }).then((resp) => {
  //         handlePageLoader(false)
  //           if (resp.data.status === 'failed') {
  //               // const ModalData = {
  //               //   title : "Oops",
  //               //   body : `${resp.data.message}`,
  //               //   btn : ["Okay"],
  //               //   id: "unsub"
  //               // }
  //               // console.log("api failed")
  //               // handleModal(ModalData, true)
  //               window.open('/home','_self');

  //           } else if (resp.data.status === 'success') {
  //               console.log("api successful")
  //               // const ModalData = {
  //               //   title : "Bravo",
  //               //   body : `${resp.data.message}`,
  //               //   btn : ["Okay"],
  //               //   id: "unsub"
  //               // }
  //               // handleModal(ModalData, true)
  //               window.open('/sub','_self');
  //           }
  //       })
      




  //       // .finally(() => {
  //       //   checkSubscriptionApi()
  //       //   }
  //       // )
  // }

  const unsubReqeustHandler = async() => {

      
    handlePageLoader(true)
    try {
      const resp = await axios({
          url: baseUrl + '/unsubscribe',
          method: 'post',
          data: { msisdn: localStorage.getItem('msisdn') },
          headers: headers
      })

     
      const currentStatus = resp.data.status
        if (resp.data.status === 'failed') {
            // const ModalData = {
            //   title : "Oops",
            //   body : `${resp.data.message}`,
            //   btn : ["Okay"],
            //   id: "unsub"
            // }
            // console.log("api failed")
            // handleModal(ModalData, true)
            let guiEvents={};
                  guiEvents['page']="unsub";
                  guiEvents["event"] = "failed";
                  guiEvents["status"] = currentStatus;
                await  SendGuiDataEvents(guiEvents);
            window.open('/unsubfail','_self');

        } else if (resp.data.status === 'success') {
            console.log("api successful")
            // const ModalData = {
            //   title : "Bravo",
            //   body : `${resp.data.message}`,
            //   btn : ["Okay"],
            //   id: "unsub"
            // }
            // handleModal(ModalData, true)
            // window.open(localStorage.getItem("subUrl"),"_self")
            let guiEvents={};
            guiEvents['page']="unsub";
            guiEvents["event"] = "success";
            guiEvents["status"] = currentStatus;
            await SendGuiDataEvents(guiEvents);
            window.open('/sub','_self');
        }
        handlePageLoader(false)
    // .finally(() => {
    //   checkSubscriptionApi()
    //   }
    // )
      }
      catch (error) {
        console.error('An error occurred while unsubscribing:', error);
        handlePageLoader(false);
        // You might want to handle errors differently, e.g., show an error message to the user
    }

  }

  const gotoHome = () => {
    window.open('/home','_self');
  }
  const showConfirmPage = ()=>{
    // window.open('/sub','_self');
    unsubReqeustHandler()
    
  }


    return (
        <div className='max-w-[500px] mx-auto  h-[100vh]'>
            {pageLoader && <LoaderComponent />}
            <div className='upper-yellow-bg bg-white backdrop-blur-sm pt-4'>
                  <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
                      </div>
                    <h5 className='has-text-black has-text-centered mt-2'>{txt.unsub[lang]}</h5>
                  </div>
        <div className='bg-unSub w-[90%] mx-auto mt-8 h-[18rem] bg-cover bg-center bg-no-repeat '></div>
        <div className='my-2 font-bold mx-auto w-[80%] text-lg text-center pt-10'> {txt.unsubtxt[lang]}</div>
       <div className='pt-10'> <div className='button is-unsub mx-auto' onClick={showConfirmPage}><button>{txt.cnfrmunsub[lang]}</button></div></div>
          
      
        
        {/* <div className='w-full mx-auto font-bold text-center'>Phone no.</div>
        <div className=''>
          <input
            ref={handle}
            className='block mx-auto text-center py-1 bg-[#607AF1] outline-none rounded-sm w-[70%] text-black font-bold px-4'
            type="text"
            value={inputState}
            maxLength={10}
            onChange={RestrictNumber}
            placeholder="enter your number"
          ></input>
        </div>


        <div onClick={() => unsubReqeustHandler(inputState)} className=' text-white text-center  py-[8px] text-xl px-5 font-bold bg-[#607AF1] w-[72%] mx-auto mt-4 rounded-3xl'>
          CONTINUE
        </div> */}
      </div>
    )
}

export default Unsub